import React, { useState,useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomFilters from "./CustomFilters";
import { Box } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

import styles from './CustomFilters.module.css'
function CustomTable({
  rows,   
  columns,
  onCellClick,
  onSelectionModelChange,
  noRowsOverlayText,
  pageSize,
  rowId,
  exportable = true,
  filterable = true,
  disableCheckboxSelection = false,
  onPageChange,
  paginationMode="client",
  rowCount,
  page
}) {
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const handleFiltersChange = (newFilterOptions) => {
    setFilterOptions(newFilterOptions);
  };
  const [dynamicRowHeight, setDynamicRowHeight] = useState(52);

  useEffect(() => {
    const updateRowHeight = () => {
      const isMobile = window.innerWidth <= 600; 
      const calculatedRowHeight = isMobile ? 90 : Math.min(100, Math.floor(window.innerHeight / 15));
      setDynamicRowHeight(calculatedRowHeight);
    };
  
    updateRowHeight();
  
    window.addEventListener("resize", updateRowHeight);
  
    return () => {
      window.removeEventListener("resize", updateRowHeight);
    };
  }, []);
  
  const filteredRows = rows.filter((row) =>
    filterOptions.every(
      (filter) =>
        !filter.field ||
        (row[filter.field] &&
          row[filter.field].toString().toLowerCase().startsWith(filter.value.toLowerCase()))
    )
  );

  const downloadBlob = (blob, fileName = "data.csv") => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const SEPARATOR = ",";

  const exportCSV = () => {
    const header = columns.map((c) => c.headerName).join(SEPARATOR);
    const rowsContent = filteredRows.map((row) =>
      columns.map((c) => row[c.field]).join(SEPARATOR)
    );

    const contents = [header].concat(rowsContent).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    downloadBlob(blob);
  };
  const handlePageChange = (page) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <div className={styles.tools}>
        {filterable &&(
      <CustomFilters
        columns={columns}
        filterOptions={filterOptions}
        onFiltersChange={handleFiltersChange}
      />
        )}
      {exportable && (
        <button
         className={styles.exportButton}
          onClick={exportCSV}
        ><DownloadIcon/>
         
        </button>
      )}
      </div>
      <div >
        <DataGrid
          sx={{ borderWidth: "0px", marginTop: "5px" ,}}
          rows={filteredRows}
          columns={columns}
          getRowId={rowId}
          disableRowSelectionOnClick
          checkboxSelection={!disableCheckboxSelection}
          onSelectionModelChange={onSelectionModelChange}
          pageSize={pageSize}
          onCellClick={onCellClick || (() => null)}
          columnUserSelect={true}
          printOptions={false}
          onSelectedRowsChange={handleSelectedRows}
          onPageChange={handlePageChange}
          density="compact"
          components={{
            NoRowsOverlay: () => (
              <div style={{ textAlign: "center", padding: "20px" }}>
                No {noRowsOverlayText} Found
              </div>
            ),
          }}
          disableColumnSelector
          disableDensitySelector
          autoHeight
          paginationMode={paginationMode}
		       page={page}
		       rowCount={rowCount}
          rowHeight={dynamicRowHeight}
          disableSelectionOnClick
        />
      </div>
    </Box>
  );
}

export default CustomTable;